import './LabelCard.css';

function LabelCard(props) {

  return (
    <div className={"LabelCard " + props.color +" "+ props.childClass}>
        <i className={props.icon}></i>&nbsp;
        <span>{props.text}</span>
    </div>
  );
}
  
export default LabelCard;
  