import './App.css';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import LoginComponent from './pages/LoginComponent/LoginComponent';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import EditPartner from './pages/EditPartner/EditPartner';
import NewPublisherExceptionalBill from './pages/NewPublisherExceptionalBill/NewPublisherExceptionalBill';
import PaymentPartnersPage from './pages/PaymentPartnersPage/PaymentPartnersPage';
import LogoutComponent from './pages/LogoutComponent/LogoutComponent';
import ExceptionalBills from './pages/ExceptionalBills/ExceptionalBills';
import PublisherPage from './pages/PublisherPage/PublisherPage';
import PartnersPage from './pages/PartnersPage/PartnersPage';
import PartnerPage from './pages/PartnerPage/PartnerPage';
import PDFViewerPage from './pages/PDFViewerPage/PDFViewerPage';
import Home from './components/Home';
import PaypalPayment from './pages/PaypalPayment/PaypalPayment';
import ImportPage from './pages/ImportPage/ImportPage';

function App() {
  return (
    <div className="App">
      <HeaderComponent/>
        <BrowserRouter className="LateralNavbar" basename={"/"}>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/logout" element={<LogoutComponent/>} />
              <Route path="/login" element={<LoginComponent/>} />
              <Route path="/publisher/:id" element={<PublisherPage/>} />
              <Route path="/partners" element={<PartnersPage/>} />
              <Route path="/partnersInvoice" element={<PaymentPartnersPage/>} />
              <Route path="/partner/:id" element={<PartnerPage/>} />
              <Route path="/paypalPayment" element={<PaypalPayment/>} />
              <Route path="/pdf/:id" element={<PDFViewerPage/>} />
              <Route path="/exceptionalBills" element={<ExceptionalBills/>} />
              <Route path="/newExceptionalBill" element={<NewPublisherExceptionalBill/>} />
              <Route path="/editPartner/:id" element={<EditPartner/>} />
              <Route path="/import" element={<ImportPage/>} />
              <Route path="*" element={<Navigate to="/" />} />
          </Routes>
      </BrowserRouter>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"/>
    </div>
  );
}

export default App;

