import 'react-toastify/dist/ReactToastify.css';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import './PaymentPartnersPage.css';
import PartnersPaymentFiller from '../../components/PartnersPaymentFiller/PartnersPaymentFiller';
import AuthComponent from '../../components/AuthComponent/AuthComponent';

function PaymentPartnersPage() {
  const pageTab = "partners_payment";

  return (
    <div className="PaymentPartnersPage">
        <AuthComponent/>
        <LateralNavComponent pageTab={pageTab}/>
        <HeadLineComponent childClass="Headline" title={"Partner's invoice"}/>
        <PartnersPaymentFiller childClass="PartnersPaymentFillerCard ShadowContainer"/>
    </div> 
  );
}
  
export default PaymentPartnersPage;