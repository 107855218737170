import axios from 'axios';
import { useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {ROOT_DOMAIN_API} from "../../Constant";
// axios.defaults.withCredentials = true;

function LogoutComponent() {
    const navigate = useNavigate();

    if(window.location.href !== window.location.origin+"/login" && !localStorage.getItem('facturation_redirection')){
        localStorage.setItem('facturation_redirection', true);
        window.location.href = window.location.origin+"/login";
    }

        
    const logout = function(){
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/billing/logout');
            const obj = await resp.data;
            if(obj){
                navigate(window.location.origin+'/login', { replace: false });
            }
        })();
        return false;
    }
    logout();

    return (
    <div className="LogoutComponent">
    </div>
    );
}

export default LogoutComponent;
