import 'react-toastify/dist/ReactToastify.css';
import './NewPublisherExceptionalBill.css';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import ExceptionalBillForm from '../../components/ExceptionalBillForm/ExceptionalBillForm';
import { ToastContainer } from "react-toastify";
import AuthComponent from '../../components/AuthComponent/AuthComponent';

function NewPublisherExceptionalBill() {
  const pageTab = "exceptional_bills";

  return (
    <div className={"NewPublisherExceptionalBill"}>
        <AuthComponent/>
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        <LateralNavComponent pageTab={pageTab}/>
        <HeadLineComponent childClass="Headline" title={"Create a new exceptional bill"}/>
        <ExceptionalBillForm childClass="ExceptionalBillFormCard" />
    </div> 
  );
}
  
export default NewPublisherExceptionalBill;