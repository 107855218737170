import 'react-toastify/dist/ReactToastify.css';
import './ExceptionalBills.css';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import { ToastContainer } from "react-toastify";
import ExceptionalFilter from '../../components/ExceptionalFilter/ExceptionalFilter';
import AuthComponent from '../../components/AuthComponent/AuthComponent';

function ExceptionalBills() {
  const pageTab = "exceptional_bills";

  return (
    <div className={"ExceptionalBills"}>
      <AuthComponent/>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Exceptional bill"/>
      <ExceptionalFilter childClass="PublisherFilterCard"/>
    </div> 
  );
}
  
export default ExceptionalBills;