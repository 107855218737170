import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './Import.css';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import {ROOT_DOMAIN_API} from "../../Constant";

function Import(props) {

    const [file, setFile] = useState();
    const [loaderFile, setLoaderFile] = useState("Hide");
    const [responseFile, setResponseFile] = useState("");
    const [separator, setSeparator] = useState(";");

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoaderFile("");
        setResponseFile("");

        const url = ROOT_DOMAIN_API+'/manage/v1/billing/importPaymentStatus';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('separator', separator);

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          }
        };

        axios.post(url, formData, config).then((response) => {
            console.log(response.data);

            setLoaderFile("Hide");

            if(response.data.status){
                setResponseFile("Import finished !");
            }else{
                setResponseFile(response.data.error);
            }
        });
    
    }

    return (
        <div className={props.childClass}>
            <div className="FlexRow MB10">
                <form onSubmit={handleSubmit}>
                    <h1>File Upload (CSV)</h1>

                    <div className="FlexRow AlignCenter">
                        <div class="FlexCol MB10">
                            <input type="file" onChange={handleChange}/>
                        </div>
                    </div>

                    <div className="FlexRow AlignCenter">
                        <div class="FlexCol MB10">
                            <h5 className="RegularTitle MT0 MB5">Separator</h5>
                            <div class="FlexRow AlignCenter">
                            <input id="separator_virgule" className="" type="checkbox"
                               onClick={() => setSeparator(",")}
                               checked={separator === ","} /><label htmlFor="separator_virgule">","</label>
                            <input id="separator_point_virgule" className="ML20" type="checkbox"
                               onClick={() => setSeparator(";")}
                               checked={separator === ";"}/><label htmlFor="separator_point_virgule">";"</label>
                            </div>
                        </div>
                    </div>

                    <button class="ActionButton MR10 BRed" type="submit">Upload</button>

                    <div id="loader_file" className={loaderFile}>
                        <ClipLoader color="#fffff" size={20} loading={true}/>
                    </div>
                    <div id="response_file">{responseFile}</div>
                </form>
            </div>
        </div> 
    );
}
  
export default Import;