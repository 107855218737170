import "./PartnerPage.css"
import 'react-toastify/dist/ReactToastify.css';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import AuthComponent from "../../components/AuthComponent/AuthComponent";

function PartnerPage() {
  const pageTab = "partners_informations";

  return (
    <div className="PartnerPage">
        <AuthComponent/>
        <LateralNavComponent pageTab={pageTab}/>
        <HeadLineComponent childClass="Headline" title={"Partner's informations"}/>
    </div> 
  );
}
  
export default PartnerPage;
  