import 'react-toastify/dist/ReactToastify.css';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import './PaypalPayment.css';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import PaypalFilter from '../../components/AuthComponent/PaypalFilter/PaypalFilter';
import { ToastContainer } from "react-toastify";

function PaypalPayment() {
  const pageTab = "paypal_api";

  return (
    <div className="PaypalPayment">
        <AuthComponent/>
        <LateralNavComponent pageTab={pageTab}/>
        <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <HeadLineComponent childClass="Headline" title={"Partner's invoice"}/>
        <PaypalFilter childClass="PartnersPaymentFillerCard ShadowContainer"/>
    </div> 
  );
}
  
export default PaypalPayment;