import "./PublisherPage.css"
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import PublisherInformations from '../../components/PublisherInformations/PublisherInformations';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import PublisherBills from "../../components/PublisherBills/PublisherBills";
import PublisherTracking from "../../components/PublisherTracking/PublisherTracking";
import AuthComponent from "../../components/AuthComponent/AuthComponent";

function PublisherPage() {
  let { id } = useParams();

  return (
    <div className="PublisherPage">
        <AuthComponent/>
        <LateralNavComponent/>
        <HeadLineComponent childClass="Headline" title={id+" - Publishers details"}/>
        <PublisherInformations childClass="PublisherInformationsCard"/>
        <PublisherBills childClass="PublisherBillsCard"/>
        <PublisherTracking childClass="PublisherTrackingCard"/>
    </div>
  );
}
  
export default PublisherPage;
  