import { useEffect, useState } from "react";
import PublishersListRow from "./PublishersListRow/PublishersListRow";
import "./PublishersList.css";
import ReactPaginate from 'react-paginate';


function PublishersList(props) {
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 20;

    useEffect(() => {
      setPageCount(Math.ceil(props.resultsCount / itemsPerPage))
    }, [props.resultsCount])

    const handlePageClick = (event) => {
        setPage(parseInt(event.selected)+1);
        props.handleOffset((event.selected * itemsPerPage));
      };

    return (
      <div className={"PublishersList ShadowContainer "+(props.childClass ? props.childClass : "")}>
        <div className="FlexRow AlignCenter JustifyBetween">
            <h2 className="RegularTitle">Publisher's list</h2>
              <table>
                <tr>
                  <td>Total HT</td>
                  <td>{props.totalAmountEur ?? "/"}€</td>
                  <td> | </td>
                  <td>{props.totalAmountDollars ?? "/"}$</td>
                </tr>
                <tr>
                  <td>Total TTC</td>
                  <td>{props.totalAmountEurWithTVA ?? "/"}€</td>
                  <td> | </td>
                  <td>{props.totalAmountDollarsWithTVA ?? "/"}$</td>
                </tr>
                <tr>
                  <td>To pay</td>
                  <td>{props.totalAmountInEurWithTVA ?? "/"}€</td>
                  <td> | </td>
                  <td>{props.totalAmountInDollarWithTVA ?? "/"}$</td>
                </tr>
              </table>
            <input onChange={props.handleSearch} className={"ActionText Width25 "} type="text" placeholder="Publisher's name"/>
        </div>
        <div className="PublishersListTable">
          <p className={"TCenter "+(props.publishersList.length<1? '' : 'Hide')}>There is no result to preview here.</p>
          <table className={"Width100 "+(props.publishersList.length>0? '' : 'Hide')}>
              <thead>
                  <tr className="BBlue">
                      <th className="P5">User ID</th>
                      <th className="P5">Invoice number</th>
                      <th className="P5">Name</th>
                      <th className="P5">Company</th>
                      <th className="P5">Payment method</th>
                      <th className="P5">Net 30/60</th>
                      <th className="P5">Amount HT</th>
                      <th className="P5">Deductions</th>
                      <th className="P5">TVA</th>
                  </tr>
              </thead>
              <tbody className="BSoftBlue">
                  {props.publishersList.map((row, key) => {
                      return <PublishersListRow
                      key={key}
                      billId={row.bill_id}
                      curency={row.bill_currency}
                      tva={row.bill_user_tva}
                      user_paypal_mail={row.user_paypal_mail}
                      net30Or60={row.user_net30==="0"?"Net 60":"Net 30"}
                      billDate={row.bill_date.split(" ")[0]}
                      billAmount={(row.bill_amount!==null ? (row.bill_currency==="$"? row.bill_amount_dollar+"$" : row.bill_amount+"€") :(row.bill_currency==="$"? row.bill_exceptional_dollar+"$" : row.bill_exceptional_euro+"€"))}
                      billExceptional={row.bill_exceptional}
                      billDeduction={(row.bill_currency==="$"? row.bill_deduction_dollar+"$" : row.bill_deduction+"€")}
                      company={row.user_entreprise}
                      userId={row.user_id}
                      userName={row.user_firstname + " "+ row.user_lastname}/>
                  })}
              </tbody>
          </table>
          <p className={"TCenter "+(props.publishersList.length>0 ? '' : 'Hide')}>Page n°{page}</p>
          <ReactPaginate
              className={"Pagination "+(props.publishersList.length>0 ? '' : 'Hide')}
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
          />
        </div>
    </div>
    );
  }
    
  export default PublishersList;
    