import './PaypalErrorRow.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';

function PaypalErrorRow(props) {
    return (
    <tr className={"PaypalErrorRow " + (props.childClass ? props.childClass : '')}>
        <td className='P5'>{props.email}</td>
        <td className='P5'>{props.amount}</td>
        <td className='P5'>{props.currency}</td>
        <td className='P5'>{props.error_message}</td>
    </tr> 
    );
}
  
export default PaypalErrorRow;