import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './PartnerBillsHistory.css';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function PartnerBillsHistory(props) {
    const [billsList, setBillsList] = useState([]);

    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const billIDBuilder = function(id, date){
      let trueID = ("F"+((date.split(" ")[0]).split('-')[0]) + "" + zeroPad(id, 4));
      return trueID;
    }
    
    useEffect(() => {
        if(!props.partnerId){
            return;
        }

        (async () => {
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/billPartner", {
                params: {
                  partner_id : props.partnerId
                }
              });
              const obj = await resp.data;
              if(obj.status&&obj.results){setBillsList(obj.results)}
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.partnerId])

  return (
    <div className={"PartnerBillsHistory ShadowContainer"}>
        <h2>Bills history</h2>
        <table className="Width100">
            <thead>
                <tr>
                    <th className="P5 BBlue TWhite">Bill ID</th>
                    <th className="P5 BBlue TWhite">Title</th>
                    <th className="P5 BBlue TWhite">Date</th>
                    <th className="P5 BBlue TWhite">Amount $</th>
                    <th className="P5 BBlue TWhite">Amount €</th>
                </tr>
            </thead>
            <tbody>
                {billsList.map((row, key) => {
                    return (
                        <tr key={key}>
                            <td className="P5"><a className="ActionLink" href={"/pdf/"+billIDBuilder(row.id, row.date)}>{billIDBuilder(row.id, row.date)}</a></td>
                            <td className="P5">{row.reference}</td>
                            <td className="P5">{row.date ? (row.date.split(" ")[0]) : ""}</td>
                            <td className="P5">{row.amount_dollar}$</td>
                            <td className="P5">{row.amount}€</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div> 
  );
}
  
export default PartnerBillsHistory;