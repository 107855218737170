import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './PartnerEditor.css';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function PartnerEditor(props) {
    const [pInfos, setPInfos] = useState({});
    const [bankList, setBankList] = useState([]);

  const editP = function(k, v){
    let tmpInfos = JSON.parse(JSON.stringify(pInfos));
      switch(k){
          case "currency":
              tmpInfos.partner_currency = v;
              break;
          case "vat":
              tmpInfos.partner_vat = v;
              break;
          case "bank":
              tmpInfos.bank_id = v;
              break;
          case "mail":
              tmpInfos.partner_email = v;
              break;
          case "address":
              tmpInfos.partner_address = v;
              break;
          case "city":
              tmpInfos.partner_city = v;
              break;
          case "country":
              tmpInfos.partner_country = v;
              break;
          case "name":
              tmpInfos.partner_name_bill = v;
              break;
          case "mailCC":
              tmpInfos.partner_email_cc = v;
              break;
          default:
              break;
      }
    setPInfos(tmpInfos);
  }

  const handleSave = function(){
    (async () => {
      try {
          const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/billing/partnerDetails", pInfos);
          const obj = await resp.data;
          if(obj.status){
            window.location = window.location.origin+"/partners";
          }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  useEffect(() => {
    if(!props.partnerId){
        return;
    }

    
    (async () => {
      try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/banksList");
          const obj = await resp.data;
          if(obj.status&&obj.results){
            setBankList(obj.results);
          }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/partnerDetails",
          {params: {
              id: props.partnerId,
              bo_billing: 1
          }});
          const obj = await resp.data;
          if(obj.status&&obj.results){
            setPInfos(obj.results);
          }
      } catch (error) {
          console.error(error);
      }
    })();


  }, [props.partnerId]);

  return (
    <div className={"PartnerEditor ShadowContainer"}>
      <h2>Edit {pInfos.partner_name_bill}</h2>
      <div className='FlexRow'>
        <div className="FlexCol Width30">
          <label htmlFor="pName">Partner name</label>
          <input id="pName" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("name", e.target.value)} value={pInfos.partner_name_bill}/>
        </div>
        <div className="FlexCol Width30 ML20">
          <label htmlFor="pMail">Partner mail</label>
          <input id="pMail" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("mail", e.target.value)} value={pInfos.partner_email}/>
        </div>
        <div className='FlexRow MT20 Width40 JustifyEnd'>
          <button className='ActionButton BRed P10' onClick={handleSave}>
            <i className="fa-solid fa-check MR5"></i>
            Save
          </button>
        </div>
      </div>
      <div className='FlexRow'>
        <div className="FlexCol Width70">
          <label htmlFor="pMailCC">Partner mail CC (Mails separated with ;)</label>
          <input id="pMailCC" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("mailCC", e.target.value)} value={pInfos.partner_email_cc}/>
        </div>
      </div>
      <div className='FlexRow'>
      <div className="FlexCol  Width30">
          <label htmlFor="pBank">Bank</label>
          <select id="pBank" className="ActionSelect P5" onChange={(e) => editP("bank", e.target.value)} defaultValue={pInfos.bank_id}>
            <option>Select a bank</option>
            {bankList.map((row, key) => {
              return <option key={key} value={row.id}>{row.name+" | "+row.iban}</option>
            })}
          </select>
        </div>
        <div className="FlexCol Width20 ML20">
          <label htmlFor="pVat">VAT</label>
          <input id="pVat" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("vat", e.target.value)} value={pInfos.partner_vat}/>
        </div>
        <div className="FlexCol  Width15 ML20">
          <label htmlFor="pIban">Currency</label>
          <select id="pIban" className="ActionSelect P5" onChange={(e) => editP("currency", e.target.value)} defaultValue={pInfos.partner_currency}>
            <option value="€">€</option>
            <option value="$">$</option>
          </select>
        </div>
      </div>
      <div className='FlexRow'>
        <div className="FlexCol Width40">
          <label htmlFor="pAddress">Address</label>
          <input id="pAddress" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("address", e.target.value)} value={pInfos.partner_address}/>
        </div>
        <div className="FlexCol Width20 ML20">
          <label htmlFor="pCity">City</label>
          <input id="pCity" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("city", e.target.value)} value={pInfos.partner_city}/>
        </div>
        <div className="FlexCol Width20 ML20">
          <label htmlFor="pCountry">Country</label>
          <input id="pCountry" type="text" className="ActionInput P5 Width100" onChange={(e) => editP("country", e.target.value)} value={pInfos.partner_country}/>
        </div>
      </div>       
    </div> 
  );
}
  
export default PartnerEditor;