import './LoginComponent.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ROOT_DOMAIN_API} from "../../Constant";

axios.defaults.withCredentials = true;

function LoginComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleUsername = event => {
        setUsername(event.target.value);
    };
    
    const handlePassword = event => {
        setPassword(event.target.value);
    };

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
          sendAuth();
        }
    }

    const sendAuth = function(){
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/billing/billing_login', {username: username, password: password});
            const obj = await resp.data;
            if(obj.status){
                navigate(window.location.origin, { replace: false });
                return false;
            } else {
                toast.error('Invalid credentials.');
            }
        })();
        return false;
    }

  return (
    <div className="loginComponent">
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        <div className="FlexCol FormContainer">
            <h2>SIGN IN TO CONTINUE.</h2>
            <div className="InputContainer">
                <input type="text" placeholder="Username" onKeyPress={handleKeyPress} onChange={handleUsername} value={username}/>
            </div>
            <div className="InputContainer">
                <input type="password" placeholder="Password" onKeyPress={handleKeyPress} onChange={handlePassword} value={password}/>
            </div>
            <button type="submit" onClick={sendAuth} className="ActionButton BRed ML20">Login</button>
        </div>
    </div>
  );
}

export default LoginComponent;
