import "./ImportPage.css"
import 'react-toastify/dist/ReactToastify.css';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import Import from '../../components/Import/Import';
import AuthComponent from "../../components/AuthComponent/AuthComponent";

function ImportPage() {
  const pageTab = "import";

  return (
    <div className="importPage">
        <AuthComponent/>
        <LateralNavComponent pageTab={pageTab}/>
        <HeadLineComponent childClass="Headline" title={"Import"}/>
        <Import childClass="ImportCard ShadowContainer"/>
    </div> 
  );
}
  
export default ImportPage;
  