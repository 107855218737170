import { useState, useEffect } from 'react';
import axios from 'axios';
import './PaypalFilter.css';
import PaypalFilterRow from './PaypalFilterRow/PaypalFilterRow';
import PaypalErrorRow from './PaypalErrorRow/PaypalErrorRow';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {ROOT_DOMAIN_API} from "../../../Constant";

function PaypalFilter(props) {
    const [yMonth, setYMonth] = useState((new Date()).getFullYear()+''+(((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1))));
    const [month, setMonth] = useState((((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1))));
    const [year, setYear] = useState((new Date()).getFullYear())
    const [yearsList, setYearsList] = useState([]);
    const [previewList, setPreviewList] = useState([]);
    const [query, setQuery] = useState("");
    const [l, setL] = useState(true);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pLoad, setPLoad] = useState(false);
    const [totalAmountDollars, setTotalAmountDollars] = useState("/");
    const [totalAmountEur, setTotalAmountEur] = useState("/");
    const [errorList, setErrorList] = useState([]);

    useEffect(() => {
        setYMonth(year+""+month);
      }, [month, year])

    useEffect(() => {
        if(query.length<3||l){
            return;
        }
        previewHandler();
    })

    useEffect(() => {
        let c = new Date().getFullYear()
        let m = 2010
        let r = []

        for (var i = c; i >= m; i--) {
            r.push(i)
        }

        setYearsList(r);
    },[]);


    useEffect(() => {
        if(!pLoad){
            console.log("nop");
            return;
        }
        console.log("preview");
        previewHandler();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset])

    const previewHandler = function(){
        (async () => {
            try {
                let payload = {
                    "year_month": yMonth,
                    "q": query,
                    "offset": offset,
                    "limit": 20
                }
                
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/paypalToPay", {params: payload});
                const obj = await resp.data;
                if(obj.status&&obj.results){
                    setPreviewList(obj.results.payload);
                    setTotalAmountDollars(obj.results.amount_dollar);
                    setTotalAmountEur(obj.results.amount_euro);
                    setPageCount(Math.ceil(obj.results.amount/20));
                    setL(false);
                    setPLoad(false);
                    setErrorList([]);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const generateHandler = function(){
        confirmAlert({
            title: '🚨Important action🚨',
            message: 'You are about to load the invoices into the Paypal api. Are you sure you want to do this?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    (async () => {
                        try {
                            let payload = {
                                "year_month": yMonth,
                            }
                            const resp = await axios.put(ROOT_DOMAIN_API+"/manage/v1/billing/paypalToPay", {params: payload});
                            const obj = await resp.data;
                            if(obj.status&&obj.results){
                                toast.success('✅ The sites for the selected period have just been loaded into the paypal api.');
                                previewHandler();
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    })();
                }
              },
              {
                label: 'No'
              }
            ]
          });
        
    }

    const proceedHandler = function(){
        confirmAlert({
            title: '🚨Important action - Confirmation🚨',
            message: 'You are about to pay all the invoices loaded into the Paypal api! This action can take up to 1min. Are you sure?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    (async () => {
                        try {
                            let payload = {
                                "live": "1",
                                "new_transfer" : "0"
                            }
                            const resp = await axios.put(ROOT_DOMAIN_API+"/manage/v1/billing/paypalPayment", {params: payload});
                            const obj = await resp.data;
                            if(obj.status&&obj.results){
                                toast.success('✅ The paypal payment was made successfully.');
                                previewHandler();
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    })();
                }
              },
              {
                label: 'No'
              }
            ]
          });
        
    }

    const errorHandler = function(){
        (async () => {
            try {
                let payload = {
                    "live": "1",
                    "year_month": yMonth,
                    "q": query,
                    "offset": offset,
                    "limit": 20
                }

                setErrorList([]);
                
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/showPaypalPaymentError", {params: payload});
                const obj = await resp.data;
                if(obj.status&&obj.results){
                    setErrorList(obj.results);
                    setPreviewList([]);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const handlePageClick = (event) => {
        setPLoad(true);
        setOffset((20*event.selected));
      };

    return (
    <div className={"PaypalFilter "+(props.childClass ? props.childClass : '')}>
        <h4 className="RegularTitle MT0 MB5">Payment Date</h4>
        <div className="FlexRow MB10">
            <div className="FlexRow">
                <select className="ActionSelect P5" onChange={(e) => setYear(e.target.value)}>
                    {yearsList.map((row, key) => {
                    return <option key={key} value={row}>{row}</option>
                    })}
                </select>
                <select className="ActionSelect P5 ML5" value={month} onChange={(e) => setMonth(e.target.value)}>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
            </div>
            <button onClick={generateHandler} className="ActionButton ActionFilters BRed ML20">
                <i className="fa-solid fa-file-arrow-up MR5"></i>
                1 - Generate
            </button>
            <button onClick={previewHandler} className="ActionButton ActionFilters BRed ML10">
                <i className="fa-solid fa-eye MR5"></i>
                2 - Preview
            </button>
            <button onClick={proceedHandler} className="ActionButton ActionFilters BRed ML10">
                <i className="fa-solid fa-triangle-exclamation MR5"></i>
                3 - Proceed to payment
            </button>
            <button onClick={errorHandler} className="ActionButton ActionFilters BRed ML10">
                <i className="fa-solid fa-bug MR5"></i>
                4 - Payment error
            </button>
        </div>
        <div className='PaypalFilterPreview MT20'>
            <div className='FlexRow JustifyBetween AlignCenter'>
                <input type="text" className="ActionInput P5 MB5" placeholder="Search" onChange={(e) => {setQuery(e.target.value)}}/>
                <p>Total {totalAmountEur ?? "/"}€ | {totalAmountDollars ?? "/"}$</p>
            </div>
            <table className='Width100'>
                <thead>
                    <tr className="BBlue TWhite">
                        <th className='P5'>Action</th>
                        <th className='P5'>Email</th>
                        <th className='P5'>User ID</th>
                        <th className='P5'>Amount €</th>
                        <th className='P5'>Amount $</th>
                        <th className='P5'>Currency</th>
                        <th className='P5'>Bill ID</th>
                        <th className='P5'>Date</th>
                        <th className='P5'>Tracking number</th>
                        <th className='P5'>State</th>
                    </tr>
                </thead>
                <tbody>
                    {previewList.map((row, key) => {
                        return <PaypalFilterRow key={key} 
                                ptp_user_id={row.ptp_user_id}
                                billId={row.ptp_bill_num} billDate={row.ptp_date} state={row.ptp_state} ptpId={row.ptp_id}
                                billAmount={row.ptp_amount} billAmountDollars={row.ptp_amount_dollar} billCurrency={row.ptp_currency}
                                email={row.ptp_mail} trackingId={row.ptp_tracking_number}/>
                    })}
                </tbody>
            </table>
        </div>
        <ReactPaginate
              className={"Pagination "+(previewList.length>0 ? '' : 'Hide')}
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
          />

        <div className={'PaypalFilterError MT20 ' + (errorList.length>0 ? '' : 'Hide')}>
            <table className='Width100'>
                <thead>
                    <tr className="BBlue TWhite">
                        <th className='P5'>Email</th>
                        <th className='P5'>Amount</th>
                        <th className='P5'>Currency</th>
                        <th className='P5'>Error message</th>
                    </tr>
                </thead>
                <tbody>
                    {errorList.map((row, key) => {
                        return <PaypalErrorRow key={key} 
                                    email={row.email}
                                    amount={row.amount}
                                    currency={row.currency}
                                    error_message={row.error_message}
                                />
                    })}
                </tbody>
            </table>
        </div>
    </div> 
    );
}
  
export default PaypalFilter;