import 'react-toastify/dist/ReactToastify.css';
import './EditPartner.css';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import { ToastContainer } from "react-toastify";
import { useParams } from 'react-router-dom';
import PartnerEditor from '../../components/PartnerEditor/PartnerEditor';
import PartnerBillsHistory from '../../components/PartnerBillsHistory/PartnerBillsHistory';
import AuthComponent from '../../components/AuthComponent/AuthComponent';

function EditPartner() {
  const pageTab = "partners_informations";
  let { id } = useParams();

  return (
    <div className="EditPartner">
      <AuthComponent/>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <PartnerEditor partnerId={id}/>
      <PartnerBillsHistory partnerId={id}/>
    </div> 
  );
}
  
export default EditPartner;