import "./ExceptionalFilter.css";
import { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import PublishersList from "../PublishersList/PublishersList";
import {ROOT_DOMAIN_API} from "../../Constant";

function ExceptionalFilter(props) {
    const [yearsList, setYearsList] = useState([]);
    const [currency] = useState("euro");
    const [exportType, setExportType] = useState("xls");
    const [netType] = useState("60");
    const [optionChoice] = useState("all");
    const [m1, setM1] = useState("01");
    const [m2, setM2] = useState((new Date().getMonth() + 1).toString().length===2 ? (new Date().getMonth() + 1).toString() : "0"+(new Date().getMonth() + 1).toString());
    const [y1, setY1] = useState((new Date().getFullYear()).toString());
    const [y2, setY2] = useState(new Date().getFullYear().toString());
    const [publishersList, setPublishersList] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const limit = 20;

    function getLastDayDate(m, year) {
        let month = m - 1;
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days.length;
    }

    useEffect(() => {
        let c = new Date().getFullYear()
        let m = 2010
        let r = []

        for (var i = c; i >= m; i--) {
            r.push(i)
        }

        setYearsList(r);
    }, []);

    const applyFilters = function(action, q = "", offset = 0){
        (async () => {
            try {
                let payload = {
                    "type": optionChoice,
                    "net30": netType,
                    "action": action,
                    "currency": currency,
                    "format": exportType,
                    "start_date": (y1+"-"+m1+"-01"),
                    "end_date": (y2+"-"+m2+"-"+(getLastDayDate(m2, y2))),
                    "offset" : offset,
                    "limit": limit,
                    "q": q
                }
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/searchBillExceptional", {params: payload});
                const obj = await resp.data;
                if(obj.status&&obj.results){
                    if(!obj.results.file){
                        setPublishersList(obj.results.payload);
                        setResultsCount(obj.results.amount);
                    }else{
                        function downloadFile(content, fileName, ext) {
                            var type_file = "";
                            if(ext === "xls"){
                                type_file = "application/vnd.ms-excel";
                            }else if(ext === "txt"){
                                type_file = "text/plain";
                            }

                            const blob = new Blob([content], { type: type_file+';charset=utf-8;' });

                            const link = document.createElement('a');

                            const url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);

                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(url);
                        }

                        downloadFile(JSON.parse(obj.results.content).body, 'export_bill_exceptional.'+obj.results.ext, obj.results.ext);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const handleOffset = function(v){
        applyFilters("preview", "", v);
    }

    const handleSearch = function(e){
        if(e.target.value.length>=2){
            applyFilters("preview", e.target.value);
        }
    }

    const newExBill = function(){
        window.location = "/newExceptionalBill"
    }

    return (
        <div className={"PublisherFilter "+(props.childClass ? props.childClass : "")}>
            <div className="ShadowContainer">
                <h1 className="RegularTitle M0">Exceptional bills filter</h1>


                <div className="FlexRow Width100">
                    <div className="FlexCol Width100 MR5">
                        <div className="FlexRow Width100 JustifyBetween">
                            <h3 className="M0">Filter publishers</h3>
                            <button onClick={newExBill} className="ActionButton BRed" href={"/newExceptionalBill"}>
                                <i class="fa-solid fa-plus MR5"></i>
                                Create Exceptional Bill
                            </button>
                        </div>
                        <div className="FlexRow WrapContent Gap5 MT10">
                            <div className="FlexCol Width20">
                                <h4 className="RegularTitle MT0 MB5">Date</h4>
                                <div className="LiteLoad M0 LightText">
                                    <div className="FlexRow AlignCenter">
                                        <select className="ActionSelect P5" onChange={(e) => setM1(e.target.value)}>
                                            <option value="01">January</option>
                                            <option value="02">February</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05">May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </select>
                                        <select className="ActionSelect P5 ML5"
                                                defaultValue={y1}
                                                onChange={(e) => setY1(e.target.value)}>
                                            {yearsList.map((row, key) => {
                                                return <option key={key} value={row}>{row}</option>
                                            })}
                                        </select>
                                        <p className="M0 ML10 MR10">-</p>
                                        <select className="ActionSelect P5"
                                                defaultValue={m2}
                                                onChange={(e) => setM2(e.target.value)}>
                                            <option value="01">January</option>
                                            <option value="02">February</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05">May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </select>
                                        <select className="ActionSelect ML5 P5" onChange={(e) => setY2(e.target.value)}>
                                            {yearsList.map((row, key) => {
                                                return <option key={key} value={row}>{row}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="Width100 M0 MT20">
                                <h4 className="RegularTitle MT0 MB5">Options</h4>
                            </div>
                            <h5 className="RegularTitle MT0 MB5">Formats</h5>
                            <div className="FlexRow AlignCenter">
                                <input id="type_xls" className="" type="checkbox"
                                       onClick={() => setExportType("xls")}
                                       checked={exportType === "xls"}/><label htmlFor="type_xls">.xls</label>
                                <input id="type_txt" className="ML20" type="checkbox"
                                       onClick={() => setExportType("txt")}
                                       checked={exportType === "txt"}/><label htmlFor="type_txt">.txt</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="FlexRow MT20">
                    <button className="ActionButton MR10 ActionFilters BRed" onClick={() => applyFilters("preview")}>Apply filters</button>
                    <button className="ActionButton ActionFilters BRed" onClick={() => applyFilters("export")}><p className="MT5 MB5">Download</p></button>
                </div>
            </div>
            <PublishersList handleSearch={handleSearch} publishersList={publishersList} resultsCount={resultsCount} handleOffset={handleOffset} childClass="PublishersListCard" />
        </div>
    );
}

export default ExceptionalFilter;