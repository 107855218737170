import { useState, useEffect } from 'react';
import axios from 'axios';
import "./PartnersListRow.css"
import { toast } from 'react-toastify';
import {ROOT_DOMAIN_API} from "../../../Constant";

function PartnersListRow(props) {
  const [partnerToBillStatus, setPartnerToBillStatus] = useState("0");
  const [paymentDelay, setPaymentDelay] = useState("60");

  useEffect(() => {
    setPartnerToBillStatus(props.partner_to_bill);
  }, [props.partner_to_bill]);

  useEffect(() => {
    setPaymentDelay(props.partner_payment_delay);
  }, [props.partner_payment_delay]);

  const updatePartnerToBillStatus = function(){
    let v = 0;
    if(partnerToBillStatus==="1"){
      setPartnerToBillStatus("0");
      v = 0;
    } else {
      setPartnerToBillStatus("1");
      v = 1;
    }
    
    (async () => {
      let payload = {
        partner_id: props.partner_id,
        to_bill: v
      }
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/billing/partnerToBill", payload);
        const obj = await resp.data;
        if(obj.status){
          toast.success('Successfully updated.');
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  const updatePaymentDelay = function(v){
    setPaymentDelay(v);
    
    (async () => {
      let payload = {
        partner_id: props.partner_id,
        payment_delay: v
      }
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/billing/partnerPaymentDelay", payload);
        const obj = await resp.data;
        if(obj.status){
          toast.success('Successfully updated.');
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  return (
    <tr className={"PartnersListRow "+props.childClass}>
        <td className="P5">{props.name}</td>
        <td className="P5"><div onClick={updatePartnerToBillStatus} className={"CircleStatus " + (partnerToBillStatus==="0" ? "BRed" : "BGreen")}></div></td>
        <td className="P5">
          <button onClick={() => updatePaymentDelay("30")} className={"ActionButton "+(paymentDelay==="30" ? "BGreen" : "BRed")}>30</button>
          <button onClick={() => updatePaymentDelay("45")} className={"ActionButton ML10 "+(paymentDelay==="45" ? "BGreen" : "BRed")}>45</button>
          <button onClick={() => updatePaymentDelay("60")} className={"ActionButton ML10 "+(paymentDelay==="60" ? "BGreen" : "BRed")}>60</button>
          <button onClick={() => updatePaymentDelay("90")} className={"ActionButton ML10 "+(paymentDelay==="90" ? "BGreen" : "BRed")}>90</button>
        </td>
        <td className="P5"><button onClick={() => {window.location = (window.location.origin+"/editPartner/"+props.partnerId)}} className="ActionButton BRed">Edit Partner</button></td>
    </tr>
  );
}
  
export default PartnersListRow;
  