import { useState } from 'react';
import axios from 'axios';
import './PaypalFilterRow.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {ROOT_DOMAIN_API} from "../../../../Constant";

function PaypalFilterRow(props) {
    const [v, setV] = useState(true);

    const handleDelete = function(){
        confirmAlert({
            title: '🚨Important action🚨',
            message: 'You are about to delete this invoice from the paypal api, are you sure?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    (async () => {
                        try {
                            let payload = {
                                "ptp_id": props.ptpId,
                            }
                            const resp = await axios.delete(ROOT_DOMAIN_API+"/manage/v1/billing/paypalToPay", {params: payload});
                            const obj = await resp.data;
                            if(obj.status&&obj.results){
                                toast.success('✅ Successfully deleted from paypal api.');
                                setV(false);
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    })();
                }
              },
              {
                label: 'No'
              }
            ]
          });
        
    }
    return (
    <tr className={"PaypalFilterRow "+ (v ? " " : " Hide ") + (props.childClass ? props.childClass : '')}>
        <td className={'P5'}>
            <button onClick={handleDelete} className={'ActionButton BRed '+(props.state!== "1" ? "" :"Hide")}>Delete</button>
        </td>
        <td className='P5'>{props.email}</td>
        <td className='P5'>
            <a className="ActionLink" href={"https://www.themoneytizer.com/crm/crm_user/"+props.ptp_user_id}>
                {props.ptp_user_id}
            </a>
        </td>
        <td className='P5'>{props.billAmount}€</td>
        <td className='P5'>{props.billAmountDollars}$</td>
        <td className='P5'>{props.billCurrency}</td>
        <td className='P5'><a className="ActionLink" target="_blank" rel="noreferrer" href={"/pdf/"+props.billId}>{props.billId}</a></td>
        <td className='P5'>{props.billDate}</td>
        <td className='P5'>{props.trackingId}</td>
        <td className='P5'>
            <div className={'BGreen TWhite P5 BorderRad3 SmallFont '+(props.state==="1" ? "" : "Hide")}>
                <i className="fa-solid fa-check"></i><span className='ML5'>Paid</span>
            </div>
            <div className={'BRed TWhite P5 BorderRad3 SmallFont '+(props.state === "1" ? "Hide" : "")}>
                <i className="fa-solid fa-check"></i><span className='ML5'>Awaiting</span>
            </div>
        </td>
    </tr> 
    );
}
  
export default PaypalFilterRow;