import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './ExceptionalBillForm.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function ExceptionalBillForm(props) {
  const [yMonth, setYMonth] = useState((new Date()).getFullYear()+''+(((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1))));
  const [month, setMonth] = useState((((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1))));
  const [year, setYear] = useState((new Date()).getFullYear());
  const [yearsList, setYearsList] = useState([]);
  const [monthsList, setMonthsList] = useState([]);
  const [optionsList, setOptionsList] = useState([{label: "", amount_dollars: 0, amount_euros:0}]);
  const [userId, setUserId] = useState(false);

  const handleAddOptions = function() {
    setOptionsList(optionsList => [...optionsList, {label: "", amount_dollars: 0, amount_euros:0}])
  }

  const handleDeleteOptions = function(key){
    let tmpList = JSON.parse(JSON.stringify(optionsList));
    tmpList.splice(key, 1)
    setOptionsList(tmpList);
  }

  const handleRowChange = function(type, key, value){
    let tmpList = JSON.parse(JSON.stringify(optionsList));
    if(type==="label"){
        tmpList[key].label = value;
    }
    if(type==="amount_dollars"){
        tmpList[key].amount_dollars = value;
    }
    if(type==="amount_euros"){
      tmpList[key].amount_euros = value;
    }
    setOptionsList(tmpList);
  }

  useEffect(() => {
    setYMonth(year+""+month);
  }, [month, year])

  useEffect(() => {
    /*let c = new Date().getFullYear();
    let m = 2010;
    let r = [];

    for (var i = c; i >= m; i--) {
        r.push(i);
    }*/

    let c = new Date().getFullYear();

    let r = [];
    r.push(c);

    setYearsList(r);
  },[]);

  useEffect(() => {
    let r = [
      {id:'01', name:'January'},
      {id:'02', name:'February'},
      {id:'03', name:'March'},
      {id:'04', name:'April'},
      {id:'05', name:'May'},
      {id:'06', name:'June'},
      {id:'07', name:'July'},
      {id:'08', name:'August'},
      {id:'09', name:'September'},
      {id:'10', name:'October'},
      {id:'11', name:'November'},
      {id:'12', name:'December'},
    ];

    let rr = r.filter(r => {
      return r.id === "" +(((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1)));
    });

    setMonthsList(rr);
  },[]);

  const handleSave = function(){
    if(!userId){
        toast.error('You can\'t create an exceptional bill without a userID.');
        return;
    }
    (async () => {
        let params = {
            user_id: userId,
            date: yMonth,
            rows: optionsList
        }
        try {
          console.log(params);
          const resp = await axios.put(ROOT_DOMAIN_API+"/manage/v1/billing/exceptionalBill", params);
          const obj = await resp.data;
          if(obj.status&&obj.results){
            window.location = window.location.origin+"/exceptionalBills";
          }
        } catch (error) {
            console.error(error);
        }
    })();
  }

  return (
    <div className={"ExceptionalBillForm ShadowContainer "+props.childClass}>
        <h2>Create new exceptional bill</h2>
        <div className='FlexCol'>
            <div className="FlexRow">
                <select className="ActionSelect P5" onChange={(e) => setYear(e.target.value)}>
                {yearsList.map((row, key) => {
                    return <option value={row}>{row}</option>
                })}
                </select>
                <select className="ActionSelect ML5 P5" onChange={(e) => setMonth(e.target.value)}>
                {monthsList.map((row, key) => {
                    return <option value={row.id}>{row.name}</option>
                })}
                </select>
            </div>
            <div className="FlexCol MT10">
                <label htmlFor="userID_field">UserID</label>
                <input id="userID_field" onChange={(e) => setUserId(e.target.value)} className="Width20" type="number"/>
            </div>
            <div className="FlexCol MT10">
                {optionsList.map((row, key) => {
                    return (<div className="FlexRow JustifyBetween AlignEnd MB5">
                        <div className="FlexCol Width30">
                            <label htmlFor={"row_label"+key}>Row label</label>
                            <input id={"row_label"+key} onChange={(e) => handleRowChange("label", key, e.target.value)} type="text" className="P5" placeholder="Label"/>
                        </div>
                        <div className="FlexCol Width30">
                            <label htmlFor={"ht_amount_euros"+key}>HT Amount €</label>
                            <input id={"ht_amount_euros"+key} onChange={(e) => handleRowChange("amount_euros", key, e.target.value)} type="number" className="P5" placeholder="Amount"/>
                        </div>
                        <div className="FlexCol Width30">
                            <label htmlFor={"ht_amount_dollars"+key}>HT Amount $</label>
                            <input id={"ht_amount_dollars"+key} onChange={(e) => handleRowChange("amount_dollars", key, e.target.value)} type="number" className="P5" placeholder="Amount"/>
                        </div>
                        <span>{props.currency}</span>
                        <button className={"ActionButton BRed ML10 "+(key!==0 ? "" : "Hide")} onClick={() => handleDeleteOptions(key)}><i className="fa-solid fa-trash"></i></button>
                        <button className={"ActionButton BRed ML10 "+(key===0 ? "" : "Hide")} onClick={handleAddOptions}><i className="fa-solid fa-plus"></i></button>
                    </div>)
                })}
            </div>
            <div className="FlexRow MT20">
                <button className={"ActionButton BRed"} onClick={handleSave}><i className="fa-solid fa-check MR5"></i>Create new exceptional bill</button>
            </div>
        </div>
    </div> 
  );
}
  
export default ExceptionalBillForm;