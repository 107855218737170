import './HeaderComponent.css';

function HeaderComponent() {
  return (
    <div>
      <nav className="Header FlexRow">
        <img src="/logo_backoffice.png" className="App-logo" alt="logo" />
        <h2 className="ML20 TWhite">Billing</h2>
      </nav>
      <div className="HeaderSpacer"></div>
    </div>
  );
}

export default HeaderComponent;
