import { useState, useEffect } from 'react';
import axios from 'axios';
import "./PartnersList.css"
import PartnersListRow from './PartnersListRow/PartnersListRow';
import {ROOT_DOMAIN_API} from "../../Constant";

function PartnersList(props) {
    const [partnersList, setPartnersList] = useState([]);

    const handleSearchPartner = function(e){
        (async () => {
            let q = e.target.value;
            if(!q){return;}
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/partnersList?bo_billing=1&q="+q);
              const obj = await resp.data;
              if(obj.status&&obj.results){setPartnersList(obj.results);}
            } catch (error) {
                console.error(error);
            }
        })();
    }

  useEffect(() => {
    (async () => {
        try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/partnersList?bo_billing=1");
          const obj = await resp.data;
          if(obj.status&&obj.results){setPartnersList(obj.results);}
          console.log(obj);
        } catch (error) {
            console.error(error);
        }
    })();
  }, []);

  return (
    <div className={"PartnersList "+props.childClass}>
        <div className="FlexRow JustifyBetween AlignCenter MB20">
            <h2>Partners list</h2>
            <div className="FlexCol">
                <input onChange={handleSearchPartner} placeholder="Partner's name" className="ActionText Width100" type="text"/>
                <button onClick={() => {window.location = window.location.origin+"/partnersInvoice"}} className="ActionButton MT20 BRed">Create invoice</button>
            </div>
        </div>
        <table className="Width100 TLeft">
            <thead className="BBlue TWhite">
                <tr>
                    <th className="P5">Partner's name</th>
                    <th className="P5">To bill ?</th>
                    <th className="P5">Net 30/60</th>
                    <th className="P5">Action</th>
                </tr>
            </thead>
            <tbody className="BSoftBlue">
                {partnersList.map((row, key) => {
                    return <PartnersListRow key={key}
                        name={row.partner_name_display2}
                        partner_id={row.partner_id}
                        partner_to_bill={row.partner_to_bill}
                        partner_payment_delay={row.partner_payment_delay}
                        partnerId={row.partner_id}/>
                })}
            </tbody>
        </table>
    </div> 
  );
}
  
export default PartnersList;
  