import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './PartnersPaymentFiller.css';
import PartnersPaymentFillerRow from './PartnersPaymentFillerRow/PartnersPaymentFillerRow';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function PartnersPaymentFiller(props) {
  const [yMonth, setYMonth] = useState((new Date()).getFullYear()+''+(((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1))));
  const [partnersList, setPartnersList] = useState([]);
  const [month, setMonth] = useState((((new Date()).getMonth()+1) > 9 ? ((new Date()).getMonth()+1) : ("0"+((new Date()).getMonth()+1))));
  const [year, setYear] = useState((new Date()).getFullYear())
  const [yearsList, setYearsList] = useState([]);
  const [v, setV] = useState(0);

  useEffect(() => {
      (async () => {
        try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/amountPartner", {
            params: {
              year_month: yMonth
            }
          });
          const obj = await resp.data;
          if(obj.status&&obj.results){setPartnersList(obj.results);}
          console.log(obj);
        } catch (error) {
            console.error(error);
        }
    })();
  }, [yMonth, v])

  useEffect(() => {
    setYMonth(year+""+month);
  }, [month, year])

  const hotReload = function(){
    setV(Date.now());
  }

  useEffect(() => {
    let c = new Date().getFullYear()
    let m = 2010
    let r = []

    for (var i = c; i >= m; i--) {
        r.push(i)
    }

    setYearsList(r);
  },[]);

  return (
    <div className={"PartnersPaymentFiller "+ props.childClass}>
      <div className="FlexRow MB10">
        <select className="ActionSelect P5" onChange={(e) => setYear(e.target.value)}>
          {yearsList.map((row, key) => {
            return <option key={key} value={row}>{row}</option>
          })}
        </select>
        <select className="ActionSelect P5 ML5" value={month} onChange={(e) => setMonth(e.target.value)}>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <table className="Width100">
        <thead>
          <tr className="BBlue TWhite">
            <th>Partner's name</th>
            <th>BO Amount</th>
            <th>Label</th>
            <th>Rows</th>
            <th></th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {partnersList.map((row, key) => {
            return <PartnersPaymentFillerRow hotReload={hotReload} key={key} date={yMonth} billId={row.bill_id} reference={row.bill_reference} createdStatus={row.bill_created} billDate={row.bill_date} sendStatus={row.bill_send} partnerId={row.partner_id} name={row.partner_name_bill} currency={row.partner_currency} revenues={row.partner_currency==="€" ? row.revenues_private : row.revenues_private_dollar} />
          })}
        </tbody>
      </table>
    </div> 
  );
}
  
export default PartnersPaymentFiller;