import 'react-toastify/dist/ReactToastify.css';

function PublisherBillsRow(props) {
  const zeroPad = (num, places) => String(num).padStart(places, '0')
  const billIDBuilder = function(id, date){
    let trueID = (date.split('-'))[0] + "-" + zeroPad(id, 6);
    return trueID;
  }

  const billPaiementDue = function(date, net30){
    let d = new Date((date.split(' '))[0]);
    if(net30===0){
      d.setMonth(d.getMonth()+2);
    } else {
      d.setMonth(d.getMonth()+1);
    }
    return d.toISOString().split('T')[0];
  }
  return (
    <tr className="BSoftBlue">
      <td className="P5">
        <div className='FlexRow AlignCenter'>
          <a className="ActionLink" href={"/pdf/"+billIDBuilder(props.bill_id, props.bill_date)}>{billIDBuilder(props.bill_id, props.bill_date)}</a>
          {props.bill_exceptional===1 ? <p className='ML20 SmallFont'>Exceptional bill</p> : (props.bill_exceptional===2 ? <p className='ML20 SmallFont'>Préavis bill</p> : "")}
        </div>
      </td>
      <td className="P5">{(props.bill_date.split(" "))[0]}</td>
      <td className="P5">{billPaiementDue(props.bill_date, props.user_net30)}</td>
      <td className="P5">{(props.bill_exceptional===1 ? (props.bill_currency==="$"? props.bill_exceptional_dollar+"$" : props.bill_exceptional_euro+"€") : (props.bill_currency==="$"? props.bill_amount_dollar+"$" : props.bill_amount+"€"))}</td>
      <td className="P5">Virement</td>
      <td className="P5">{props.payment_state}</td>
      <td className="P5">{props.payment_method_name}</td>
      <td className="P5">{props.payment_state_name}</td>
      <td className="P5">{props.payment_error}</td>
    </tr>
  );
}
  
export default PublisherBillsRow;
  