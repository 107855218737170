import LateralNavComponent from "./LateralNavComponent/LateralNavComponent";
import './Home.css';
import HeadLineComponent from "./HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import PublisherFilter from "./PublisherFilter/PublisherFilter";
import AuthComponent from "./AuthComponent/AuthComponent";

function Home() {
  const pageTab = "publishers";

  return (
    <div className="Home">
      <AuthComponent/>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Welcome to the billing dashboard"/>
      <PublisherFilter childClass="PublisherFilterCard"/>
    </div>
  );
}

export default Home;
