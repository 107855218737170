import "./PDFViewerPage.css"
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import LateralNavComponent from "../../components/LateralNavComponent/LateralNavComponent";
import HeadLineComponent from "../../components/HeadLine/HeadLine";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import {ROOT_DOMAIN_API} from "../../Constant";

function PDFViewerPage() {
  let { id } = useParams();
  const [base64Payload, setBase64Payload] = useState("");
  const [lang, setLang] = useState("ww");
  const [userId, setUserId] = useState("0");

  const loadPDF = function(id, type, lang){
    (async () => {
      try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/getBillFromID?q="+id+"&t="+type+"&l="+lang);
          const obj = await resp.data;
          if(obj.status&&obj.results){
            setBase64Payload(obj.results);
            setUserId(obj.user_id);
          }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  useEffect(() => {
    if(!id){
      return;
    }
    if(id.charAt(0)==="f"||id.charAt(0)==="F"){
      loadPDF(id.substring(5, 9), "partner", lang);
    } else {
      loadPDF(id.substring(5, 11), "publisher", lang);
    }    
  }, [id, lang])

  return (
    <div className="PDFViewerPage">
      <AuthComponent/>
      <LateralNavComponent/>
      <HeadLineComponent childClass="Headline" title={"Invoice n°"+id} user_id={userId} />
      <div className="ShadowContainer PDFContainer">
        <select onChange={(e) => setLang(e.target.value)} className="ActionSelect P5 BRed TWhite MB10">
          <option value="ww">FR</option>
          <option value="us">EN</option>
          <option value="es">ES</option>
          <option value="de">DE</option>
        </select>
        <iframe title={"Invoice "+id} src={"data:application/pdf;base64,"+base64Payload} height="100%" width="100%"></iframe>
      </div>
    </div>
  );
}
  
export default PDFViewerPage;
  