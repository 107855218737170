import "./PartnersPage.css"
import 'react-toastify/dist/ReactToastify.css';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineComponent from '../../components/HeadLine/HeadLine';
import PartnersList from '../../components/PartnersList/PartnersList';
import AuthComponent from "../../components/AuthComponent/AuthComponent";

function PartnersPage() {
  const pageTab = "partners_informations";

  return (
    <div className="PartnersPage">
        <AuthComponent/>
        <LateralNavComponent pageTab={pageTab}/>
        <HeadLineComponent childClass="Headline" title={"Partners list"}/>
        <PartnersList childClass="PartnersListCard ShadowContainer"/>
    </div> 
  );
}
  
export default PartnersPage;
  