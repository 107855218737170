import './LateralNavComponent.css';
import {Link} from "react-router-dom";
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function LateralNavComponent(props) {
  const [toggleLateralNavBar, setToggleLateralNavBar] = useState(false);
  const [selectedResult, setSelectedResult] = useState("");
  const navigate = useNavigate();

  const handleToggleLateralNavBar = function(){
    if(toggleLateralNavBar){
      setToggleLateralNavBar(false);
      return;
    }
    setToggleLateralNavBar(true);
  }

  const handleSelection = value => {
    setSelectedResult(value);

    if(value.type === "user"){
      navigate('/publisher/'+ value.user_id, { replace: true });
    }else if(value.type === "bill"){
      navigate('/pdf/'+ value.num_bill, { replace: true });
    }
  }

  const loadOptions = (inputValue) => {
    if(!inputValue){
      return null;
    }
    return axios(ROOT_DOMAIN_API+'/manage/v1/billing/searchBillOrUser?q='+inputValue)
    .then(res => res.data)
    .then(data => data.results)
  };

  return (
    <div>
      <nav className={"LateralNavbar " + (toggleLateralNavBar ? "" : "Hide")}>
          <div className="CloseContainer">
            <button onClick={handleToggleLateralNavBar}><i className="fa-solid fa-xmark"></i></button>
          </div>
          <ul>
              <li className={props.pageTab === "import" ? "Highlight" : ""}>
                <AsyncSelect
                  className="SelectField MR10"
                  cacheOptions
                  defaultOptions
                  value={selectedResult}
                  getOptionLabel={e => {return "["+e.user_id+"] "+e.firstname+" "+e.lastname} }
                  getOptionValue={e => e.user_id}
                  loadOptions={loadOptions}
                  onChange={handleSelection}
                />
              </li>
              <li className='Width80'>
                <hr/>
              </li>
              <li className='MT20 BSoftBlue'>
              BO Suppliers
              </li>
              <li className={props.pageTab === "publishers" ? "Highlight" : ""}>
                <Link to="/"><i className="fa-solid fa-file-invoice-dollar"></i> Publisher's payment</Link>
              </li>
              <li className={props.pageTab === "exceptional_bills" ? "Highlight" : ""}>
                <Link to="/exceptionalBills"><i className="fa-solid fa-triangle-exclamation"></i> Exceptional invoice</Link>
              </li>
              <li className={props.pageTab === "paypal_api" ? "Highlight" : ""}>
                <Link to="/paypalPayment"><i className="fa-brands fa-paypal"></i> Paypal API</Link>
              </li>
              <li className={props.pageTab === "import" ? "Highlight" : ""}>
                <Link to="/import"><i className="fa-solid fa-upload"></i> Import payment returns</Link>
              </li>
              <li className='Width80'>
                <hr/>
              </li>
              <li className='BSoftBlue'>
              BO Clients
              </li>
              <li className={props.pageTab === "partners_informations" ? "Highlight" : ""}>
                <Link to="/partners"><i className="fa-solid fa-hat-cowboy-side"></i> Partner's informations</Link>
              </li>
              <li className={props.pageTab === "partners_payment" ? "Highlight" : ""}>
                <Link to="/partnersInvoice"><i className="fa-solid fa-file-invoice-dollar"></i> Partner's invoice</Link>
              </li>
              <li className='Width80'>
                <hr/>
              </li>
              <li className={props.pageTab === "logout" ? "Highlight" : ""}>
                <Link to="/logout"><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</Link>
              </li>
          </ul>
      </nav>
      <button onClick={handleToggleLateralNavBar} className={"BurgerMenu " + (toggleLateralNavBar ? "Hide" : "")}><i className="fa-solid fa-bars"></i></button>
    </div>
  );
}

export default LateralNavComponent;
