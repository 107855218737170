import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './PartnersPaymentFillerRow.css';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../../Constant";

function PartnersPaymentFillerRow(props) {

  const [optionsList, setOptionsList] = useState([{label: "", amount_dollars: 0, amount_euros:0}]);
  const [label, setLabel] = useState("");
  const [pdfPayload, setPDFPayload] = useState(null);
  

  const handleAddOptions = function() {
    setOptionsList(optionsList => [...optionsList, {label: "", amount_dollars: 0, amount_euros:0}])
  }

  const handleDeleteOptions = function(key){
    let tmpList = JSON.parse(JSON.stringify(optionsList));
    tmpList.splice(key, 1)
    setOptionsList(tmpList);
  }

  const handleRowChange = function(type, key, value){
    let tmpList = JSON.parse(JSON.stringify(optionsList));
    if(type==="label"){
        tmpList[key].label = value;
    }
    if(type==="amount_dollars"){
        tmpList[key].amount_dollars = value;
    }
    if(type==="amount_euros"){
      tmpList[key].amount_euros = value;
    }
    setOptionsList(tmpList);
  }

  
  const showPreview = function(){
    (async () => {
        let payload = {
            partner_id: props.partnerId,
            label: label,
            date: props.date,
            rows: optionsList
        }
        let params = {
            t: "preview_partner",
            l: "ww",
            q: payload
        }
        try {
          const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/billing/getBillFromID", params);
          const obj = await resp.data;
          if(obj.status&&obj.results){setPDFPayload(obj.results);}
          console.log(obj);
        } catch (error) {
            console.error(error);
        }
    })();
    
  }

  const createInvoice = function(){
    (async () => {
        let payload = {
            partner_id: props.partnerId,
            label: label,
            date: props.date,
            rows: optionsList
        }
        let params = {
            t: "preview_partner",
            l: "ww",
            q: payload
        }
        try {
          const resp = await axios.put(ROOT_DOMAIN_API+"/manage/v1/billing/billPartner", params);
          const obj = await resp.data;
          if(obj.status&&obj.results){
            props.hotReload();
          }
        } catch (error) {
            console.error(error);
        }
    })();    
  }

  const sendBill = function(sendType, billId){
    (async () => {
        let payload = {
            sendType: sendType,
            billId: billId
        }
        let params = {
            t: "send_bill",
            l: "ww",
            q: payload
        }
        try {
          const resp = await axios.put(ROOT_DOMAIN_API+"/manage/v1/billing/billPartner", params);
          const obj = await resp.data;
          if(obj.status&&obj.results){
            props.hotReload();
          }
        } catch (error) {
            console.error(error);
        }
    })();    
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')
  const billIDBuilder = function(id, date){
      console.log(date);
      console.log(((date.split(" ")[0]).split('-'))[0]);
    let trueID = ("F"+((date.split(" ")[0]).split('-')[0]) + "" + zeroPad(id, 4));
    return trueID;
  }

  return (
    <tr className={"PartnersPaymentFillerRow VAlignTop BSoftBlue "+ props.childClass}>
        <td className="P5">{props.name}</td>
        <td className="P5">{props.revenues}{props.currency}</td>
        <td colSpan={3} className={(props.createdStatus === 1&&props.sendStatus !== "1") ? "" : 'Hide'}>
          <div className='FlexRow'>
            <div className='FlexRow P5'>
              <div className='BGreen TWhite P5 BorderRad3 SmallFont'><i className="fa-solid fa-check"></i><span className='ML5'>Created</span></div>
            </div>
            <div className='FlexRow P5 AlignCenter'>
              <p className="M0">{props.reference ? props.reference : "Untitled"} - <a className="ActionLink" href={"/pdf/"+(props.billDate&&props.billId ? billIDBuilder(props.billId, props.billDate) : "")}>{props.billDate&&props.billId ? billIDBuilder(props.billId, props.billDate) : ""}</a></p>
            </div>
          </div>
        </td>
        <td colSpan={3} className={props.sendStatus === "1" ? "" : 'Hide'}>
          <div className='FlexRow'>
            <div className='FlexRow P5'>
              <div className='BYellow TWhite P5 BorderRad3 SmallFont'><i className="fa-solid fa-check"></i><span className='ML5'>Send</span></div>
            </div>
            <div className='FlexRow P5 AlignCenter'>
              <p className="M0">{props.reference ? props.reference : "Untitled"} - <a className="ActionLink" href={"/pdf/"+(props.billDate&&props.billId ? billIDBuilder(props.billId, props.billDate) : "")}>{props.billDate&&props.billId ? billIDBuilder(props.billId, props.billDate) : ""}</a></p>
            </div>
          </div>
        </td>
        <td className={'P5 '+((props.sendStatus === "1" || props.createdStatus === 1) ? "Hide" : "")}>
          <div className="FlexCol">
            <label htmlFor={"invoice-label-"+props.partnerId} >Invoice Label</label>
            <input id={"invoice-label-"+props.partnerId} onChange={(e) => setLabel(e.target.value)} className="P5 Width80" type="text" placeholder='Title'/>
          </div>
        </td>
        <td className={"P5 "+((props.sendStatus === "1" || props.createdStatus === 1) ? "Hide" : "")}>
            {optionsList.map((row, key) => {
                return (<div className="FlexRow JustifyBetween AlignEnd MB5">
                  <div className="FlexCol">
                    <label htmlFor={"amount-label-"+key+props.partnerId}>Row Label</label>
                    <input id={"amount-label-"+key+props.partnerId} onChange={(e) => handleRowChange("label", key, e.target.value)} type="text" className="P5" placeholder="Label"/>
                  </div>
                  <div className="FlexCol Width30">
                    <label htmlFor={"amount-euros-"+key+props.partnerId}>Amount €</label>
                    <input id={"amount-euros-"+key+props.partnerId} onChange={(e) => handleRowChange("amount_euros", key, e.target.value)} type="number" className="P5" placeholder="Amount"/>
                  </div>
                  <div className="FlexCol Width30">
                    <label htmlFor={"amount-dollars-"+key+props.partnerId}>Amount $</label>
                    <input id={"amount-dollars-"+key+props.partnerId} onChange={(e) => handleRowChange("amount_dollars", key, e.target.value)} type="number" className="P5" placeholder="Amount"/>
                  </div>
                  <button className={"ActionButton ML10 BRed "+(key!==0 ? "" : "Hide")} onClick={() => handleDeleteOptions(key)}><i className="fa-solid fa-trash"></i></button>
                  <button className={"ActionButton ML10 BRed "+(key===0 ? "" : "Hide")} onClick={handleAddOptions}><i className="fa-solid fa-plus"></i></button>
                </div>)
            })}
        </td>
        <td className={"P5 "+((props.sendStatus === "1" || props.createdStatus === 1) ? "Hide" : "")}>
          {props.currency}
        </td>
        <td className="P5 VAlignBottom">
            <div className={'FlexRow '+(props.createdStatus === 1 ? "Hide" : "")}>
              <button className={"ActionButton ML10 BRed"} onClick={showPreview}><i className="fa-solid fa-eye MR5"></i>Preview</button>
              <button className={"ActionButton ML10 BRed"} onClick={createInvoice}><i className="fa-solid fa-plus MR5"></i>Create</button>
            </div>
            <div className={'FlexRow '+(props.createdStatus === 1 ? "" : "Hide ")+(props.sendStatus === "1" ? "Hide" : "")}>
              <button className={"ActionButton ML10 BYellow"} onClick={() => sendBill("1", props.billId)}><i className="fa-solid fa-paper-plane MR5"></i>Send mail</button>
              <button className={"ActionButton ML10 BOrange"} onClick={() => sendBill("0", props.billId)}><i className="fa-solid fa-hand MR5"></i>Manually send</button>
            </div>
            <div className={"PDFPopUp BWhite P10 "+(pdfPayload ? '' : 'Hide')}>
              <div className='Width100 FlexRow Height100'>
                <div className='FlexCol MR5'>
                  <button className="ActionButton BRed" onClick={() => setPDFPayload(null)}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <iframe title={"Preview Invoice"} src={"data:application/pdf;base64,"+pdfPayload} height="100%" width="100%"></iframe>
              </div>
            </div>
        </td>
    </tr> 
  );
}
  
export default PartnersPaymentFillerRow;