import { useNavigate} from "react-router-dom";
import { useEffect} from 'react';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function AuthComponent() {
  const navigate = useNavigate();

  useEffect(()=> {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/billingVAuth");
        const obj = await resp.data;
        if(obj.status&&obj.results){
          localStorage.setItem('global_name', obj.results.username);
          return;
        }
        navigate('/login/', { replace: true });
      } catch (error) {
          console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="Auth"></div>
  );
}

export default AuthComponent;
