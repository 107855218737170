import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import PublisherTrackingRow from './PublisherTrackingRow/PublisherTrackingRow';
import {ROOT_DOMAIN_API} from "../../Constant";

function PublisherTracking(props) {
  let { id } = useParams();
  const [trackingPaymentMethodList, setTrackingPaymentMethodList] = useState([]);

  useEffect(() => {
    if(!id){
      return;
    }

    (async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/publisherTrackingPaymentMethod?q="+id);
            const obj = await resp.data;
            if(obj.status&&obj.results){
                setTrackingPaymentMethodList(obj.results);
            }
        } catch (error) {
            console.error(error);
        }
    })();
  }, [id]);

  return (
    <div className={"PublisherInformations ShadowContainer "+(props.childClass ? props.childClass : "")}>
        <h1 className="RegularTitle">Publisher's updated payment method</h1>
        <table className={"Width100 borderCollapse "+(trackingPaymentMethodList.length<1?'Hide':'')}>
            <thead className="BBlue TWhite">
                <tr>
                    <th>Date</th>
                    <th></th>
                    <th>Name</th>
                    <th>Bank Name</th>
                    <th>Bank Adress</th>
                    <th>Bank ZIP</th>
                    <th>Bank City</th>
                    <th>Bank Country</th>
                    <th>Bank IBAN</th>
                    <th>Bank BIC</th>
                    <th>Bank Name Inter</th>
                    <th>Bank City Inter</th>
                    <th>Bank Country Inter</th>
                    <th>Bank IBAN Inter</th>
                    <th>Bank BIC Inter</th>
                    <th>Paypal</th>
                </tr>
            </thead>
            <tbody>
                {trackingPaymentMethodList.map((row, key) => {
                    return (
                        <PublisherTrackingRow row={row} />
                    )
                })}
            </tbody>
        </table>
        <p className={"TCenter "+(trackingPaymentMethodList.length<1?'':'Hide')}>There is no result to preview here.</p>
    </div>
  );
}
  
export default PublisherTracking;