import "./PublisherFilter.css";
import { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import PublishersList from "../PublishersList/PublishersList";
import ClipLoader from "react-spinners/ClipLoader";
import {ROOT_DOMAIN_API} from "../../Constant";

function PublisherFilter(props) {
    const [yearsList, setYearsList] = useState([]);
    const [currency, setCurrency] = useState("euro");
    const [payment, setPayment] = useState("0");
    const [exportType, setExportType] = useState("xls");
    const [netType, setNetType] = useState("60");
    const [optionChoice, setOptionChoice] = useState("all");
    const [m1, setM1] = useState((new Date().getMonth() + 1).toString().length===2 ? (new Date().getMonth() + 1).toString() : "0"+(new Date().getMonth() + 1).toString());
    const [y1, setY1] = useState((new Date().getFullYear()).toString());
    const [publishersList, setPublishersList] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [l, setL] = useState(false);
    const [pdfPayload,setPDFPayload] = useState(false);
    const [extraPDF, setExtraPDF] = useState("create");
    const [extraPDFId, setExtraPDFId] = useState(false);
    const [totalAmountDollars, setTotalAmountDollars] = useState("/");
    const [totalAmountEur, setTotalAmountEur] = useState("/");
    const [totalAmountDollarsWithTVA, setTotalAmountDollarsWithTVA] = useState("/");
    const [totalAmountEurWithTVA, setTotalAmountEurWithTVA] = useState("/");
    const [totalAmountInEurWithTVA, setTotalAmountInEurWithTVA] = useState("/");
    const [totalAmountInDollarWithTVA, setTotalAmountInDollarWithTVA] = useState("/");
    const limit = 20;

    function getLastDayDate(m, year) {
        let month = m - 1;
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
          days.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return days.length;
    }

    useEffect(() => {
    let c = new Date().getFullYear()
    let m = 2010
    let r = []

    for (var i = c; i >= m; i--) {
        r.push(i)
    }

    setYearsList(r);
    }, []);

    useEffect(() => {
        if(localStorage.getItem("extraPDFId")){
            setExtraPDF("export");
            setExtraPDFId(localStorage.getItem("extraPDFId"));
        }
    }, [])


    useEffect(() => {
        if(!extraPDFId){
            return;
        }
        getExport();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraPDFId])

    const applyFilters = function(action, q = "", offset = 0){
        (async () => {
            try {
                let payload = {
                    "type": optionChoice,
                    "net30": netType,
                    "action": action,
                    "currency": currency,
                    "format": exportType,
                    "payment": payment,
                    "start_date": (y1+"-"+m1+"-01"),
                    "end_date": (y1+"-"+m1+"-"+(getLastDayDate(m1, y1))),
                    "offset" : offset,
                    "limit": limit,
                    "q": q,
                    "pdf": extraPDF,
                    "pdf_id": extraPDFId
                }
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/searchBill", {params: payload});
                const obj = await resp.data;
                if(obj.status&&obj.results){
                    if(action==="preview"){
                        if(obj.status&&obj.results){
                            setPublishersList(obj.results.payload);
                            setResultsCount(obj.results.amount);
                            setTotalAmountDollars(obj.results.amount_dollar)
                            setTotalAmountEur(obj.results.amount_euro)
                            setTotalAmountDollarsWithTVA(obj.results.amount_dollar_with_tva)
                            setTotalAmountEurWithTVA(obj.results.amount_euro_with_tva)
                            setTotalAmountInEurWithTVA(obj.results.amount_in_euro)
                            setTotalAmountInDollarWithTVA(obj.results.amount_in_dollar)
                            setL(true);
                        }
                    }
                    if(action==="export"){
                        console.log(obj.results);

                        if(obj.results.ext === "zip"){
                            function openZip(base64ImageData) {
                                var contentType = "application/zip";

                                base64ImageData = base64ImageData.replace('data:application/zip;base64,', '');

                                console.log(base64ImageData);

                                const byteCharacters = atob(base64ImageData);
                                const byteArrays = [];

                                for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                                    const slice = byteCharacters.slice(offset, offset + 1024);

                                    const byteNumbers = new Array(slice.length);
                                    for (let i = 0; i < slice.length; i++) {
                                        byteNumbers[i] = slice.charCodeAt(i);
                                    }

                                    const byteArray = new Uint8Array(byteNumbers);

                                    byteArrays.push(byteArray);
                                }
                                const blob = new Blob(byteArrays, { type: contentType });
                                const blobUrl = URL.createObjectURL(blob);

                                window.open(blobUrl, '_blank');
                            }

                            openZip(JSON.parse(obj.results.content).body);
                        }else{
                            function downloadFile(content, fileName, ext) {
                                var type_file = "";
                                if(ext === "xls"){
                                    type_file = "application/vnd.ms-excel";
                                }else if(ext === "txt"){
                                    type_file = "text/plain";
                                }

                                const blob = new Blob([content], { type: type_file+';charset=utf-8;' });

                                const link = document.createElement('a');

                                const url = URL.createObjectURL(blob);
                                link.setAttribute('href', url);

                                link.setAttribute('download', fileName);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                URL.revokeObjectURL(url);
                            }

                            downloadFile(JSON.parse(obj.results.content).body, obj.results.filename, obj.results.ext);
                        }
                    }
                    if(action==="pdf"){
                        setExtraPDF("export");
                        setExtraPDFId(obj.results);
                        console.log("Updating localstorage");
                        console.log(obj.results);
                        localStorage.setItem("extraPDFId", obj.results);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const getExport = function(){
        console.log(extraPDFId);
        if(!extraPDFId){
            setTimeout(() => {
                getExport();
            }, 5000)
            return;
        }
        (async () => {
            try {
                let payload = {
                    "type": optionChoice,
                    "net30": netType,
                    "action": "pdf",
                    "currency": currency,
                    "format": exportType,
                    "payment": payment,
                    "start_date": (y1+"-"+m1+"-01"),
                    "end_date": (y1+"-"+m1+"-"+(getLastDayDate(m1, y1))),
                    "offset" : 0,
                    "limit": limit,
                    "q": "",
                    "pdf": extraPDF,
                    "pdf_id": extraPDFId
                }
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/searchBill", {params: payload});
                const obj = await resp.data;
                if(obj.status&&obj.results){
                    setExtraPDF("create");
                    localStorage.removeItem("extraPDFId");
                    // var link = document.createElement('a');
                    // link.href = obj.results;
                    // link.download = 'export_bill.pdf';
                    // link.dispatchEvent(new MouseEvent('click'));

                    const openImg = function (base64ImageData) {
                        var contentType = "application/pdf";

                        base64ImageData = base64ImageData.replace('data:application/pdf;base64,', '');

                        console.log(base64ImageData);

                        const byteCharacters = atob(base64ImageData);
                        const byteArrays = [];

                        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                            const slice = byteCharacters.slice(offset, offset + 1024);

                            const byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);

                            byteArrays.push(byteArray);
                        }
                        const blob = new Blob(byteArrays, { type: contentType });
                        const blobUrl = URL.createObjectURL(blob);

                        window.open(blobUrl, '_blank');
                    }

                    openImg(obj.results.content);
                } else {
                    setTimeout(() => {
                        getExport();
                    }, 5000)
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    const handleOffset = function(v){
        applyFilters("preview", "", v);
    }

    const handleSearch = function(e){
        if(!l){
            return;
        }
        if(e.target.value.length>2){
            applyFilters("preview", e.target.value);
        } else {
            applyFilters("preview");
        }
    }

    return (
    <div className={"PublisherFilter "+(props.childClass ? props.childClass : "")}>
        <div className="ShadowContainer">
            <h1 className="RegularTitle">Publishers payments</h1>
            <div className="FlexRow Width100">
                <div className="FlexCol Width50 MR5"> 
                    <h3 className="M0">Filter publishers</h3>
                    <div className="FlexRow WrapContent Gap5 MT10">
                        <div className="FlexCol Width20">
                            <h4 className="RegularTitle MT0 MB5">Payment Date</h4>
                            <div className="LiteLoad M0 LightText">
                                <div className="FlexRow AlignCenter">
                                    <select className="ActionSelect P5" onChange={(e) => setM1(e.target.value)} defaultValue={m1}>
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                    <select className="ActionSelect P5 ML5"
                                        defaultValue={y1}
                                        onChange={(e) => setY1(e.target.value)}>
                                        {yearsList.map((row, key) => {
                                            return <option key={key} value={row}>{row}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="Width100 M0 MT20">
                            <h4 className="RegularTitle MT0 MB5">Options</h4>
                        </div>
                        <div className="FlexCol">
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="all">Everything (ZIP)</label></h5>
                                <input id="all" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("all")}
                                    checked={optionChoice === "all"}/>
                            </div>
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="all_in_one_file">Everything (one file for Marie-Laure)</label></h5>
                                <input id="all_in_one_file" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("all_in_one_file")}
                                    checked={optionChoice === "all_in_one_file"}/>
                            </div>
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="sepa">SEPA Export</label></h5>
                                <input id="sepa" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("sepa")}
                                    checked={optionChoice === "sepa"}/>
                            </div>
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="paypal">Paypal Export</label></h5>
                                <input id="paypal" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("paypal")}
                                    checked={optionChoice === "paypal"}/>
                            </div>
                        </div>
                        <div className="FlexCol ML20">
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="international">International Export</label></h5>
                                <input id="international" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("international")}
                                    checked={optionChoice === "international"}/>
                            </div>
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="international_inter">International Bank Inter Export</label></h5>
                                <input id="international_inter" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("bank_inter")}
                                    checked={optionChoice === "bank_inter"}/>
                            </div>
                            <div className="FlexRow AlignCenter">
                                <h5 className="RegularTitle MT0 MB5"><label htmlFor="other_invoice">Other invoices</label></h5>
                                <input id="other_invoice" className="ML10" type="checkbox"
                                    onClick={() => setOptionChoice("other")}
                                    checked={optionChoice === "other"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="VerticalSeparator"></div>
                <div className="FlexRow WrapContent ML5 Gap5 Width50 ML5 HFitContent">
                    <div className="FlexRow WrapContent Gap5 MT10">
                        <div className="Width100 M0 MT20">
                            <h4 className="RegularTitle MT0 MB5">Options</h4>
                        </div>
                        <div className="FlexCol">
                            <div className="FlexCol MB10 Hide">
                                <h5 className="RegularTitle MT0 MB5">Net</h5>
                                <div className="FlexRow AlignCenter">
                                    <input id="net30" className="" type="checkbox"
                                        onClick={() => setNetType("30")}
                                        checked={netType === "30"}/><label htmlFor="net30">30</label>
                                    <input id="net60" className="ML20" type="checkbox"
                                        onClick={() => setNetType("60")}
                                        checked={netType === "60"}/><label htmlFor="net60">60</label>
                                </div>
                            </div>
                            <div className="FlexCol MB10">
                                <h5 className="RegularTitle MT0 MB5">Formats</h5>
                                <div className="FlexRow AlignCenter">
                                    <input id="formats_xls" className="" type="checkbox"
                                        onClick={() => setExportType("xls")}
                                        checked={exportType === "xls"}/><label htmlFor="formats_xls">.xls</label>
                                    <input id="formats_txt" className="ML20" type="checkbox"
                                        onClick={() => setExportType("txt")}
                                        checked={exportType === "txt"}/><label htmlFor="formats_txt">.txt</label>
                                </div>
                            </div>
                            <div className="FlexCol MB10 Hide">
                                <h5 className="RegularTitle MT0 MB5">Currency</h5>
                                <div className="FlexRow AlignCenter">
                                    <input id="currency_euro" className="" type="checkbox"
                                        onClick={() => setCurrency("euro")}
                                        checked={currency === "euro"} /><label htmlFor="currency_euro">€</label>
                                    <input id="currency_dollar" className="ML20" type="checkbox"
                                        onClick={() => setCurrency("dollar")}
                                        checked={currency === "dollar"}/><label htmlFor="currency_dollar">$</label>
                                </div>
                            </div>
                            <div className="FlexCol MB10">
                                <h5 className="RegularTitle MT0 MB5">Payment</h5>
                                <div className="FlexRow AlignCenter">
                                    <input id="payment_paid" className="" type="checkbox"
                                        onClick={() => setPayment("1")}
                                        checked={payment === "1"} /><label htmlFor="payment_paid">Paid</label>
                                    <input id="payment_unpaid" className="ML20" type="checkbox"
                                        onClick={() => setPayment("0")}
                                        checked={payment === "0"}/><label htmlFor="payment_unpaid">Unpaid</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="FlexRow MT20">
                <button className="ActionButton MR10 ActionFilters BRed" onClick={() => applyFilters("preview")}>Apply filters</button>
                <button className="ActionButton MR10 ActionFilters BRed" onClick={() => applyFilters("export")}><p className="MT5 MB5">Download</p></button>
                <button className={"ActionButton ActionFilters BRed"+(extraPDF === "export" ? " Disabled" : "")} disabled={(extraPDF === "export")} onClick={() => applyFilters("pdf")}><p className="MT5 MB5">PDF Download</p></button>     
            </div>
            <div className={"MT5 FlexRow AlignCenter BorderRad3 P10 BSoftRed Bord1 BBordRed TWhite WidthFitContent " + (extraPDF === "export" ? "" : "Hide")}>
                <ClipLoader color="#fffff" size={15} loading={true}/>
                <p className="M0 ML10 SmallFont">PDF Generation in progress, please wait. Do not refresh this page during the process.</p>
            </div>
        </div>
        <div className={"PDFPopUp BWhite P10 "+(pdfPayload ? '' : 'Hide')}>
            <div className='Width100 FlexRow Height100'>
                <div className='FlexCol MR5'>
                    <button className="ActionButton BRed" onClick={() => setPDFPayload(null)}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <iframe title={"PDF export"} src={"data:application/pdf;base64,"+pdfPayload} height="100%" width="100%"></iframe>
            </div>
        </div>
        <PublishersList handleSearch={handleSearch} totalAmountDollars={totalAmountDollars} totalAmountEur={totalAmountEur}
        totalAmountDollarsWithTVA={totalAmountDollarsWithTVA}
        totalAmountEurWithTVA={totalAmountEurWithTVA}
        publishersList={publishersList} resultsCount={resultsCount} handleOffset={handleOffset}
        totalAmountInEurWithTVA={totalAmountInEurWithTVA} totalAmountInDollarWithTVA={totalAmountInDollarWithTVA}
        childClass="PublishersListCard" />
    </div>
  );
}
  
export default PublisherFilter;
  