function PublishersListRow(props) {
  const zeroPad = (num, places) => String(num).padStart(places, '0')
  const billIDBuilder = function(id, date){
    let trueID = (date.split('-'))[0] + "-" + zeroPad(id, 6);
    return trueID;
  }
  return (
    <tr className={"PublishersListRow "+(props.childClass ? props.childClass : "")+" "+(props.billExceptional === 1 ? "BVerySoftRed" : (props.billExceptional === 2 ? "BSky" : ""))}>
      <td>
        <a className="ActionLink" href={"publisher/"+props.userId}>
          {props.userId}
        </a>
      </td>
      <td>
          <a className="ActionLink" target="_blank" rel="noreferrer" href={"/pdf/"+billIDBuilder(props.billId, props.billDate)}>{billIDBuilder(props.billId, props.billDate)}</a>
      </td>
      <td>
          {props.userName}
      </td>
      <td>
          {props.company}
      </td>
      <td>
          { props.user_paypal_mail !== "" && props.user_paypal_mail != null ? "Paypal" : "Wire transfer"}
      </td>
      <td>
          {props.net30Or60}
      </td>
      <td>
        {props.billAmount}
      </td>
      <td>
        {props.billDeduction}
      </td>
      <td>
          {props.tva}
      </td>
    </tr>
  );
}
    
export default PublishersListRow;
    