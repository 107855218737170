function PublisherTrackingRow(props) {
  return (
    <>
        <tr>
            <td rowSpan={2}>{props.row.tupm_date_modification}</td>
            <td>After</td>
            <td className={props.row.tupm_user_bank_name !== props.row.tupm_user_bank_name_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_name_modified}
            </td>
            <td className={props.row.tupm_user_bank_namebank !== props.row.tupm_user_bank_namebank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_namebank_modified}
            </td>
            <td className={props.row.tupm_user_bank_adressbank !== props.row.tupm_user_bank_adressbank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_adressbank_modified}
            </td>
            <td className={props.row.tupm_user_bank_zipbank !== props.row.tupm_user_bank_zipbank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_zipbank_modified}
            </td>
            <td className={props.row.tupm_user_bank_citybank !== props.row.tupm_user_bank_citybank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_citybank_modified}
            </td>
            <td className={props.row.tupm_user_bank_countrybank !== props.row.tupm_user_bank_countrybank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_countrybank_modified}
            </td>
            <td className={props.row.tupm_user_bank_iban !== props.row.tupm_user_bank_iban_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_iban_modified}
            </td>
            <td className={props.row.tupm_user_bank_bic !== props.row.tupm_user_bank_bic_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_bic_modified}
            </td>
            <td className={props.row.tupm_user_bank_namebank_inter !== props.row.tupm_user_bank_namebank_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_namebank_inter_modified}
            </td>
            <td className={props.row.tupm_user_bank_citybank_inter !== props.row.tupm_user_bank_citybank_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_citybank_inter_modified}
            </td>
            <td className={props.row.tupm_user_bank_countrybank_inter !== props.row.tupm_user_bank_countrybank_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_countrybank_inter_modified}
            </td>
            <td className={props.row.tupm_user_bank_iban_inter !== props.row.tupm_user_bank_iban_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_iban_inter_modified}
            </td>
            <td className={props.row.tupm_user_bank_bic_inter !== props.row.tupm_user_bank_bic_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_bic_inter_modified}
            </td>
            <td className={props.row.tupm_user_paypal_mail !== props.row.tupm_user_paypal_mail_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_paypal_mail_modified}
            </td>
        </tr>
        <tr>
            <td>Before</td>
            <td className={props.row.tupm_user_bank_name !== props.row.tupm_user_bank_name_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_name}
            </td>
            <td className={props.row.tupm_user_bank_namebank !== props.row.tupm_user_bank_namebank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_namebank}
            </td>
            <td className={props.row.tupm_user_bank_adressbank !== props.row.tupm_user_bank_adressbank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_adressbank}
            </td>
            <td className={props.row.tupm_user_bank_zipbank !== props.row.tupm_user_bank_zipbank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_zipbank}
            </td>
            <td className={props.row.tupm_user_bank_citybank !== props.row.tupm_user_bank_citybank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_citybank}
            </td>
            <td className={props.row.tupm_user_bank_countrybank !== props.row.tupm_user_bank_countrybank_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_countrybank}
            </td>
            <td className={props.row.tupm_user_bank_iban !== props.row.tupm_user_bank_iban_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_iban}
            </td>
            <td className={props.row.tupm_user_bank_bic !== props.row.tupm_user_bank_bic_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_bic}
            </td>
            <td className={props.row.tupm_user_bank_namebank_inter !== props.row.tupm_user_bank_namebank_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_namebank_inter}
            </td>
            <td className={props.row.tupm_user_bank_citybank_inter !== props.row.tupm_user_bank_citybank_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_citybank_inter}
            </td>
            <td className={props.row.tupm_user_bank_countrybank_inter !== props.row.tupm_user_bank_countrybank_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_countrybank_inter}
            </td>
            <td className={props.row.tupm_user_bank_iban_inter !== props.row.tupm_user_bank_iban_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_iban_inter}
            </td>
            <td className={props.row.tupm_user_bank_bic_inter !== props.row.tupm_user_bank_bic_inter_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_bank_bic_inter}
            </td>
            <td className={props.row.tupm_user_paypal_mail !== props.row.tupm_user_paypal_mail_modified ? "BSoftRed" : "BSoftGreen"}>
                {props.row.tupm_user_paypal_mail}
            </td>
        </tr>
        <tr className='borderSeparate'>
            <td></td>
        </tr>
    </>
  );
}
  
export default PublisherTrackingRow;
  